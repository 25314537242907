.errorStack {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}
.errorStack h3 {
  margin: 0.3rem 0px;
}
.errorContainer {
  margin: 3% 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.errorContainer h1,
.errorContainer h2 {
  margin-bottom: 3%;
}
