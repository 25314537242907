.headerContainer {
  height: 86px;
  position: sticky;
  top: 0px;
  z-index: 5;
}

.menuIconContainer {
  width: 35px;
  height: 35px;
  background: #148dad;
}

.messagesMenuIcon {
  font-size: 18px !important;
}

.notifiedIcon {
  width: 13px;
  height: 13px;
  top: -5px;
  right: -5px;
}

.notifDrop {
  position: absolute;
  top: 100%;
  right: 25px;
}

.notifDropdownContainer {
  width: 360px;
  box-shadow: 0px 3px 15px #0000000d;
}
.notifDropdownContent {
  overflow-y: scroll;
  max-height: 75vh;
}

.ThumbnailNotifContainer {
  width: 39px;
  height: 39px;
  min-width: 39px;
}

.profileDropdownContainer {
  box-shadow: 0px 3px 15px #0000000d;
  top: calc(100% - 8px);
  width: 222px;
  right: 33px;
}
.profileDropdownContainer .dropdownBtn:hover {
  font-weight: 600;
  color: #14748e !important;
}
.headerContainer .headerSearch input {
  border: 1px #14748e solid !important;
}
