.listDeleteIcon {
  width: 33px;
  height: 33px;
}
.listDeleteIcon i {
  -webkit-text-stroke: 0.5px;
}
.listDeleteIcon:hover {
  background-color: #ffcfcf !important;
}
