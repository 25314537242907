.switchButton {
  width: 35px;
  height: 17.5px;
  background-color: #e3e3e3;
  border-radius: 10px;
  border: 0px;
  padding: 2px;
  transition: all ease-in-out 0.2s;
}
.filterSwitchButton {
  width: 58px;
  height: 29px;
  background-color: #e3e3e3;
  border-radius: 18px;
  border: 0px;
  padding: 2px;
  transition: all ease-in-out 0.2s;
}
.switchCircle {
  width: 13px;
  height: 13px;
  background-color: #59a8c4;
  border-radius: 50px;
  transition: all ease-in-out 0.2s;
}
.filterSwitchCircle {
  width: 19px;
  height: 19px;
  background-color: #59a8c4;
  border-radius: 50px;
  transition: all ease-in-out 0.2s;
}
.switchButton.on {
  background-color: var(--filterSwitchBtnColor);
}
.filterSwitchButton.on {
  background-color: #59a8c4;
}

.switchButton.on .switchCircle {
  transform: translateX(18px);
  background-color: var(--filterSwitchCircleColor);
}
.filterSwitchButton.on .filterSwitchCircle {
  transform: translateX(32px);
  background-color: white;
}
