.calendar-event {
  min-width: 162px !important;
  min-height: 72px;
}

.vertical-bar {
  width: 5px;
  min-height: 40px;
}

.calendar-event div:first-child {
  --bs-bg-opacity: 1 !important;
}
