.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.sBold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.Xbold {
  font-weight: 800;
}
.italic {
  font-style: italic;
}
