.textArea:focus-visible {
  outline: none;
  border: 2px #14748e solid !important;
}
.textArea:hover {
  border: 2px #14748e solid !important;
}
.textArea::placeholder {
  color: #333333;
}
