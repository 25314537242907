.CartContainer {
  position: fixed;
  right: 0px;
  top: 86px;
  width: calc(35vw - 130px);
  height: calc(100vh - 90px);
  box-shadow: 0 2px 15px #0000000d;
}
.cartElementList {
  overflow: scroll;
  max-height: calc(100vh - 350px);
}
.refClientItem {
  outline: none;
  border: 1px solid rgb(20, 116, 142, 0.6);
  border-radius: 5px;
}
