.newCatalogueCardContainer {
  /* box-shadow: 0px 2px 6px #0000000d; */
  width: 49% !important;
  transition: all 0.2s ease-in-out;
  border-radius: 10px;
  cursor: pointer;
  /* width: 23%; */
}
.packingsList {
  height: 300px;
  overflow: scroll;
}
.newCatalogueImg {
  height: 200px;
  max-width: 100%;
}
.newCatalogueCardContainer:hover {
  transform: scale(1.02);
  box-shadow: 0px 2px 22px #00000033;
}
.packingItem {
  border-bottom: 1px solid #dee2e6;
}
.qteButton {
  width: 30px;
  height: 30px;
  border: none;
  outline: none;
  background-color: #1d4955;
  color: white;
}
