.filtersContainer .filterSubmitBtn {
  width: 170px;
  height: 40px;
}
.filtersContainer {
  animation: pop 0.3s ease-in-out;
}

@keyframes pop {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
