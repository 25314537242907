.customCheckBoxContainer {
  width: 25px;
  height: 25px;
}
.customCheckBox {
  width: 19px;
  height: 19px;
  border: 1px #bababa solid;
  border-radius: 3px;
}
.customCheckBox:hover {
  background-color: #efefef;
}
.checkedCustomCheckBox {
  margin-bottom: 2px;
}
.checkedCustomCheckBox:hover path[name="line"] {
  fill: #abb8b2;
}
