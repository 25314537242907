.centeredModalHeader {
  padding: 10px 12px !important;
  border: 0;
}

.centeredModal {
  max-width: 980px !important;
  border: 0 !important;
}
.centeredModal .modal-content {
  border-radius: unset;
  border: unset;
}
.modal-backdrop.show {
  background-color: #65646459;
}
