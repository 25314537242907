.deliveryDetails .deliveryDropdown {
  min-width: 175px;
}
.deliveryOption:hover {
  border: 1px #14748e solid !important;
}
.deliveryOption.selected {
  border: 1px #14748e solid !important;
}
.deliveryDetails .delvieryTextArea:hover,
.deliveryDetails .delvieryTextArea:focus {
  border: 1px #14748e solid !important;
}
.deliveryAddressModal textarea:hover {
  border: 1px #14748e solid !important;
}
.deliveryAddressModalContainer {
  height: 600px;
}
.deliveryAddressModal textarea:focus {
  border: 1px #14748e solid !important;
}
.centeredModal:has(.deliveryDialog) {
  width: fit-content !important;
}
