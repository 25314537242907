@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans";
}

pre {
  font-family: "Open Sans" !important;
  text-wrap: wrap !important;
}
.t02 {
  transition: all 0.2s ease-in-out;
}
a {
  text-decoration: none !important;
}
svg {
  transition: all 0.2s ease-in-out;
}

/* right modal title */
.rightModalTitle {
  font-size: 25px;
  font-weight: 600;
  color: #14748e;
}
/* Fonts style */
h2,
.quickSand {
  font-family: "Quicksand" !important;
}
.borderTest {
  border: 2px solid red !important;
}

.transition02 {
  transition: all 0.2s ease-in-out;
}
.pointer {
  cursor: pointer !important;
}
body::-webkit-scrollbar {
  display: none !important;
}
*::-webkit-scrollbar {
  display: block !important;
  width: 5px !important;
}

*::-webkit-scrollbar-track {
  background: #ffffff !important;
}

*::-webkit-scrollbar-thumb {
  background-color: #ebebeb !important;
  border-radius: 35px;
}
*::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}
* {
  scrollbar-color: #ebebeb #ffffff !important;
  scrollbar-width: thin;
}

/* TEXT ALIGN */
.textStart {
  text-align: left !important;
}
.textCenter {
  text-align: center !important;
}
.textEnd {
  text-align: right !important;
}
.underline {
  text-decoration: underline !important;
}
.underline:hover {
  color: #6cc3e3 !important;
}
.textNoWrap {
  white-space: nowrap;
}

/* Main layout */
.mainWithSideBar {
  width: calc(100% - 420px);
}

/* CUSTOM FLEX */
.alignH {
  display: flex;
  align-items: center;
}
.alignV {
  display: flex;
  justify-content: center;
}
.alignC {
  display: flex;
  align-items: center;
  justify-content: center;
}

.colAlignC {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}

/* CUSTOM WIDTHS */
.w10 {
  width: 10%;
}
.w20 {
  width: 20%;
}
.w30 {
  width: 30%;
}
.w40 {
  width: 40%;
}

.w-100px {
  width: 100px;
  min-width: 100px;
}

/* BORDER RADIUS */
.br4 {
  border-radius: 4px;
}
.br5 {
  border-radius: 5px;
}
.br8 {
  border-radius: 8px;
}
.br10 {
  border-radius: 10px;
}
.br14 {
  border-radius: 14px;
}
.br30 {
  border-radius: 30px;
}
.br10Left {
  border-radius: 10px 0 0 10px;
}
.br10Right {
  border-radius: 0px 10px 10px 0;
}

/* BACKGROUND COLOR */
.bg-blue {
  background-color: blue;
}

/* CUSTOM FONT SIZE */
.f8 {
  font-size: 10px;
  line-height: 14px;
}
.f10 {
  font-size: 10px;
}
.f13 {
  font-size: 13px;
}
.f14 {
  font-size: 14px;
}
.f16 {
  font-size: 16px;
}
.f22 {
  font-size: 22px;
}
.f30 {
  font-size: 30px;
}
.f44 {
  font-size: 44px;
}

/* CUSTOM FONT WHEIGHTS */
.normal {
  font-weight: 400;
}
.medium {
  font-weight: 500;
}
.semi-bold {
  font-weight: 600;
}
.bold {
  font-weight: 700;
}

/* BORDER  */
.border-1 {
  border-style: solid;
  border-width: 1px;
}
.border-1-yellow {
  border: 1px solid grey;
}
.border-2 {
  border-style: solid;
  border-width: 2px;
}

/* SHADOWS */
.shadow26 {
  box-shadow: 0px 2px 15px #00000026 !important;
}
.shadow14 {
  box-shadow: 0px 2px 15px #00000014;
}
/* Others */
.wordBreak {
  word-break: break-word !important;
}

/*  HEIGHT */
.h100 {
  height: 100%;
}
.h90 {
  height: 90%;
}
.vh100 {
  height: 100vh;
}

.h-100px {
  height: 100px;
}

.h-36px {
  height: 36px;
}

.max-h-200px {
  max-height: 200px;
}

.max-h-400px {
  height: 400px !important;
  max-height: 400px !important;
  min-height: 400px !important;
}

.max-w-500px {
  max-width: 500px !important;
}

.max-w-900px {
  max-width: 900px !important;
}

.min-w-600px {
  min-width: 500px;
}

/* For calendar events  */
.bg-pink,
.bg-green,
.bg-yellow,
.bg-redCalendar,
.bg-lightBluee,
.bg-purple,
.bg-blueCalendar,
.bg-blueSky {
  --bs-bg-opacity: 0.2 !important;
}

/* react prime */
.p-dropdown:not(.p-disabled).p-focus,
.p-inputtext:enabled:focus,
.p-accordion-header-link {
  box-shadow: none !important;
}
.ob-contain {
  object-fit: contain;
}
.ob-cover {
  object-fit: cover;
}
.object-cover {
  object-fit: cover !important;
}
.scale-3-50 {
  transform: scale(3.5);
}

.imageInput {
  width: 141px;
  height: 141px;
}

.backdrop {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0.75 !important;
  display: inline-block;
  animation: fadeIn 0.1s ease-in-out forwards;
}

.close-btn-modal {
  position: absolute;
  top: 20px;
  right: 16px;
  width: 34px;
  height: 34px;
  border: none;
  outline: none;
  z-index: 2;
}

.download-btn-modal {
  position: absolute;
  top: 20px;
  left: 16px;
  width: 34px;
  height: 34px;
  border: none;
  outline: none;
  z-index: 2;
}

.z-9999 {
  z-index: 9999;
}
.avatarContainer {
  width: 43px;
  height: 43px;
}
.textNoWrap {
  white-space: nowrap;
}
.deleteButton {
  padding-top: 8px;
  padding-bottom: 8px;
  min-height: 40px;
}

.search-close {
  right: 65px;
  top: 50%;
  transform: translateY(-50%);
}

.search-close svg {
  width: 10px;
}

.search-close svg path {
  fill: var(--bs-heavyMetal);
}

.maxW123 {
  max-width: 123px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.min-h-70 {
  min-height: 70%;
}
.coverImg label {
  width: 100%;
}
.minW250 {
  min-width: 180px;
}
.scroll-snap-Xmandatory {
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  scroll-behavior: smooth;
}
.scroll-snap-Xmandatory > * {
  scroll-snap-align: center;
}
.scroll-snap-Ymandatory {
  scroll-snap-type: Y mandatory;
  overflow-x: scroll;
  scroll-behavior: smooth;
}
.scroll-snap-Ymandatory > * {
  scroll-snap-align: end;
}
#pr_id_3 > button {
  padding: 0 !important;
  background-color: white;
  border: 2px solid #d9e1e7 !important;
  border-left: 0 !important;
}
#pr_id_3 > button .p-icon {
  color: #809fb8;
}
.css-cp01gg-control {
  border: 2px solid #d9e1e7 !important;
  padding: 6px 1rem;
  border-radius: 6px;
}
.css-cp01gg-control:hover {
  border: 2px #14748e solid !important;
}
.css-100ebu3-menu {
  background-color: white !important;
  border-radius: 6px;
  padding-top: 10px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  overflow: scroll;
  scroll-behavior: smooth;
  /* max-height: 200px; */
}
.css-bio7mv-option {
  padding: 16px 10px 16px;
  color: #666666;
}
.css-bio7mv-option:hover {
  background-color: #e9ecef;
}
.css-g56vrd-indicatorContainer {
  color: #809fb8;
}
.cursor-pointer {
  cursor: pointer;
}
.shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.min-200 {
  min-width: 200px;
}
.w101px {
  width: 101px;
  min-width: 101px;
}
.extra-field {
  transition-duration: 500ms;
  transition-timing-function: ease;
  transition-property: all;
  border-radius: 5px;
}
.extra-field:hover {
  background-color: #b8b5b565;
}
.extra-field:hover .delete-extra-field {
  opacity: 1;
  display: block;
}

.delete-extra-field {
  display: none;
  opacity: 0;
  transition-duration: 500ms;
  transition-timing-function: ease;
  transition-property: all;
}
.right-0 {
  right: 0;
}
.scale-07 {
  scale: 0.5;
}

.ellipsis-text {
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
}

.max-fit-content {
  width: fit-content;
}

.max-w-300px {
  max-width: 300px !important;
}

.max-w420px {
  max-width: 420px !important;
}

.h-40px {
  height: 40px !important;
}

.p-12px {
  padding: 12px;
}

.gap-100 {
  gap: 100px;
}

.size-20px {
  width: 20px !important;
  height: 20px !important;
}
.table-row.expanded .verticalPoints,
.table-row.expanded .verticalPoints.active {
  color: white !important;
}
.table-row.expanded .verticalPoints.active:hover {
  color: #14748e !important;
}

.max-w-100 {
  max-width: 100%;
}

.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.size-24px {
  width: 24px !important;
  height: 24px !important;
}

.size-50px {
  width: 50px;
  height: 50px;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.gallery-container {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 1rem;
  /*:w*/
}

.gallery-item-wide {
  grid-column: 1/6;
  grid-row: 1/-1;
  display: block;
  max-height: 900px;
}

.side-images-1 {
  grid-column: 6/-1;
  grid-row: 1/2;
  display: block;
  max-height: 450px;
}

.side-images-2 {
  grid-column: 6/-1;
  grid-row: 2/-1;
  display: block;
  max-height: 450px;
}

.aspect-square {
  aspect-ratio: 1 / 1;
}

.max-h-900px {
  max-height: 900px;
}

.parent-hover:hover .child-hover-show {
  display: flex !important;
}

@import url("./CommonStyles/flex.css");
@import url("./CommonStyles/fontSize.css");
@import url("./CommonStyles/fontweight.css");
@import url("./CommonStyles/width.css");
@import url("./CommonStyles/borderRadius.css");
@import url("./CommonStyles/border.css");
@import url("./CommonStyles/spacing.css");
@import url("./CommonStyles/zindex.css");
