.calendarInput {
  border: 2px solid #d9e1e7 !important;
  border-radius: 6px;
  height: 40px;
}
.calendarInput:hover {
  border: 2px solid #14748e !important;
}
.calendarInput .p-inputtext::placeholder {
  color: #06152b !important;
}
.calendarInput.p-inputwrapper-focus {
  border: 2px solid #14748e !important;
}

.calendarInput .p-button {
  background-color: transparent;
  border: 0px;
  border-radius: 0px 5px 5px 0px !important;
}
.calendarInput .p-button:focus {
  outline: none;
  box-shadow: none;
}
.calendarInput .p-button:hover {
  background-color: #efefef !important;
}
.calendarInput .p-inputtext {
  border: 0px;
  /* font-size: 16px; */
}
.calendarInput .calendarInputIcon {
  color: #809fb8;
  font-size: var(--calendarIconSize) !important;
}
.calendarInput .p-datepicker table td {
  padding: 5px;
}
.calendarInput .p-datepicker table td > span {
  width: 26px;
  height: 26px;
}
.calendarInput .p-datepicker table th > span {
  font-size: 13px;
}

.calendarInput .p-button-icon-only {
  background-color: white !important;
}
