.newTicketSAV {
  height: 100%;
}
.newTicketSAV .warrantyEndDate {
  cursor: text !important;
}
.newSAVAttachements .controlBtns button {
  color: #d5cece;
  cursor: not-allowed;
}
.newSAVAttachements .selectAllBtn.active {
  color: #707070;
  cursor: pointer;
}
.newSAVAttachements .unselectAllbtn.active {
  color: #707070;
  cursor: pointer;
}
.newSAVAttachements .deleteBtn.active {
  color: #db2323;
  cursor: pointer;
}
.newSAVAttachements .deleteBtn.active:hover {
  background-color: #db23235f !important;
}

.newSAVAttachements .selectAllBtn:hover {
  background-color: #efefef !important;
}
.newSAVAttachements .unselectAllbtn:hover {
  background-color: #efefef !important;
}
.newSAVAttachements .deleteBtn:hover {
  background-color: #efefef !important;
}
.newSAVAttachements .attachmentCheckBox {
  right: 20px;
  top: 10px;
}
.newSAVAttachements .attachmentItem.selected {
  border: 2px solid #14748e !important;
}
.newSAVAttachements .attachmentItem:hover {
  border: 2px solid #14748e !important;
  box-shadow: 0px 0px 15px 3px rgba(120, 117, 117, 0.75);
}
.newSAVAttachements .attachmentItem:hover {
  border: 2px solid #14748e !important;
  box-shadow: 0px 0px 15px 3px rgba(120, 117, 117, 0.75);
}
/************************** comment section *********************************/
.newSavComment {
  max-height: calc(100vh - 297px) !important;
  height: calc(100vh - 297px) !important;
}
.newSavComment .messageInput:focus-visible {
  outline: none;
  border: 2px #14748e solid !important;
}
.newSavComment .messageInput:hover {
  border: 2px #14748e solid !important;
}
.newSavComment .sendBtn {
  right: 15px;
  bottom: 10px;
}
.newSavComment .attachmentInput {
  margin-bottom: -4px;
  transform: rotate(45deg);
  transform-origin: center;
  width: 45px;
  height: 45px;
}
.newSavComment .attachmentInput:hover {
  background-color: #efefef;
}
.newSavComment .messageContainer {
  max-width: 70%;
}
.newSavComment .formContainer {
  bottom: 0px;
  padding-bottom: 2px;
  background-color: white !important;
}
.rightModalContent:has(.newSavComment) {
  padding-bottom: 1.5rem !important;
}
.messageContainer .message-user {
  width: 33px;
  height: 33px;
  object-fit: cover;
  border-radius: 50%;
}

.messageInput {
  transition: all 0.2s ease-in-out;
}

.messageInput textarea {
  transition: all 0.2s ease-in-out;
  outline: none;
  resize: none;
  max-height: 96px;
  height: 24px;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.messageInput textarea::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.messageInput textarea {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.message-media-close {
  right: -14px;
  top: -20px;
  transform: scale(0.7);
  border-radius: 30%;
}

/****************************** sav summary **********************************/
.newSavSummary .firstRow {
  min-height: 40%;
}
.newSavSummary .secondRow,
.newSavSummary .thridRow {
  min-height: 30%;
}
.newSavSummary .descriptionContainer {
  min-height: 40%;
}
.newSavSummary .checkIcon {
  -webkit-text-stroke: 0.3px;
  color: #1ad598;
}
.newSavSummary .attachments:hover {
  outline: 2px solid #14748e;
}
