.paymentDetails .paymentOption:hover {
  border: 1px #14748e solid !important;
}
.paymentDetails .paymentOption.selected {
  border: 1px #14748e solid !important;
}
.paymentDetails .ibanInput {
  height: 40px;
}
.paymentDetails .ibanInput:focus-visible {
  outline: none;
  border: 1px #14748e solid !important;
}
.paymentDetails .inputEye {
  width: 30px;
  height: 30px;
  right: 10px;
  top: 5px;
}
.paymentDetails .inputEye:hover {
  background-color: #efefef;
}
.paymentPanierSummary {
  height: 100%;
}
.paymentPanierSummary .childrenContainer {
  height: 53%;
}
