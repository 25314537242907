.factureItem:hover {
  background-color: #f8f8f8;
}
.factureItem.checked {
  background-color: #f8f8f8;
}
.factureItem .status {
  width: 84px;
  background-color: #eef1f0;
  color: #abb8b2;
}
.factureItem .status.paid {
  background-color: #d1f7ea;
  color: #1ad598 !important;
}
