.calendarToolbar .toolbarFilterBtn:hover {
  background-color: white !important;
  outline: 2px#14748e solid !important;
  color: #14748e !important;
}
.calendarToolbar .toolbarFilterBtn.active {
  background-color: white !important;
  outline: 2px#14748e solid !important;
  color: #14748e !important;
}
