.centeredModal:has(.image-modal) {
  width: fit-content !important;
  max-width: fit-content !important;
  max-height: 100%;
}

.modal-content:has(.image-modal) {
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.modal-body:has(.image-modal) {
  padding-bottom: 24px !important;
}

.image-modal {
  /* display: none; */
  max-height: calc(100vh - 36px - var(--bs-modal-margin) * 2);
}

.image-modal img {
  /* display: none; */
  max-height: 80vh;
}

.image-modal button {
  position: relative;
}

.image-modal .loading p {
  opacity: 0;
}

.image-modal .small-spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
