$theme-colors: (
  "white": #ffffff,
  "grey": #d9d9d9,
  "softGrey": #d9e1e7,
  "lightGrey": #efefef,
  "dimGrey": #f8f8f8,
  "darkGrey": #abb8b2,
  "whyBotherGrey": #4a4a4a,
  "brightGrey": #666666,
  "smoothGrey": #858997,
  "blueGrey": #809fb8,
  "fadedGrey": #84a2ba,
  "blue": #14748e,
  "softBlue": #59a8c4,
  "smoothBlue": #c4e0ea,
  "fadedBlue": #e4e8ef,
  "skyBlue": #f0f7fa,
  "blueCalendar": #14748e,
  "red": #cc1f08,
  "lameRed": #f90a1a,
  "dark": #333333,
  "customBlack": #010101,
  "ultraBlack": #050505,
  "softBlack": #06152b,
  "heavyMetal": #2b3228,
  "mountainMist": #959396,
  "lightGreen": #809fb8,
  "greenPist": #27d79e,
  "greenStock": #00bf63,
  "softGreen": #b5eddb,
  "orange": rgb(241, 188, 30),
  "lightOrange": #feefd8,
  "turquoise": #1eb1d8,
  "pinkWithoutOp": #db5aee,
  "lightPink": #ffeafd,
  "softPink": #fed9ee,
  "greenWithoutOp": #1ad598,
  "redWithoutOp": #ea3a3d,
  "lightBlack": #666666,
  "brightRed": #ff0d0d,
  "lightRose": #f863634d,
  "moutarde": #fbc515,
  "lightYellow": #fbc515,
  "yellowOrange": #fcb03e,
  "wildStrawberry": #fc43ac,
  "aquaGreen": #1ad5af,
  "iceberg": #d1f7ef,
  "papaya": #feefd8,
  "darkBlue": #1d4955,
  "blueTurquoise": #3abaea,
  "light-grey-1": #eeeeee,
  /***************
 For calendar events 
 *****************/ "pink": #db5aee,
  "green": #1ad59833,
  "lightBlue": #59a8c433,
  "lightBluee": #1eb1d8,
  "redCalendar": #ea3a3d,
  "yellow": #f9b95933,
  "purple": #3a36db33,
  "blueSky": #3abaea33,
  "jasminePurple": #b03aea,
  "yellowGreen": #8ee917,
  "snowGreen": #e8fbd1,
  "treebrown": #d8c9bc,
  "oakBrown": #8b7558,
  "brown": #c9762e,
  "light-brown": #e2a281,
  // Repeated
  "whiteGrey": #f8f8f8,
  "lightYelloww": #fef9f2,
  "newBlue": #6fb5be,
  "red-300": #ff8282,
  "red-400": #ff5757,
  "red-500": #ff3131,
  "red-700": #c00000,
  "green-200": #6fb5be,
  "green-500": #41cf8b,
  "green-700": #085e75,
  "bronze-100": #f6c5ab,
  "bronze-200": #ffad7a,
  "bronze-300": #cabaab,
  "bronze-500": #c9762e,
  "gray-500": #878a8b,
  "blue-25": #94adb2,
  "blue-50": #829da5,
  "blue-100": #d5e7fa,
  "blue-200": #72b6c0,
  "blue-600": #4892dc,
  "blue-500": #577b87,
  "blue-900": #59638b,
  "gray-200": #f4f6fd,
  "violet-500": #a97eff,
  "violet-400": #d86ccc,
);

$enable-negative-margins: true;

$table-variants: ();

$table-variants: map-merge(
  (
    "white": map-get($theme-colors, "white"),
    "grey": map-get($theme-colors, "grey"),
    "softGrey": map-get($theme-colors, "softGrey"),
    "lightGrey": map-get($theme-colors, "lightGrey"),
    "dimGrey": map-get($theme-colors, "dimGrey"),
    "darkGrey": map-get($theme-colors, "darkGrey"),
    "whyBotherGrey": map-get($theme-colors, "whyBotherGrey"),
    "brightGrey": map-get($theme-colors, "brightGrey"),
    "smoothGrey": map-get($theme-colors, "smoothGrey"),
    "blueGrey": map-get($theme-colors, "blueGrey"),
    "fadedGrey": map-get($theme-colors, "fadedGrey"),
    "blue": map-get($theme-colors, "blue"),
    "softBlue": map-get($theme-colors, "softBlue"),
    "smoothBlue": map-get($theme-colors, "smoothBlue"),
    "fadedBlue": map-get($theme-colors, "fadedBlue"),
    "skyBlue": map-get($theme-colors, "skyBlue"),
    "blueCalendar": map-get($theme-colors, "blueCalendar"),
    "red": map-get($theme-colors, "red"),
    "lameRed": map-get($theme-colors, "lameRed"),
    "dark": map-get($theme-colors, "dark"),
    "customBlack": map-get($theme-colors, "customBlack"),
    "ultraBlack": map-get($theme-colors, "ultraBlack"),
    "softBlack": map-get($theme-colors, "softBlack"),
    "heavyMetal": map-get($theme-colors, "heavyMetal"),
    "mountainMist": map-get($theme-colors, "mountainMist"),
    "lightGreen": map-get($theme-colors, "lightGreen"),
    "greenPist": map-get($theme-colors, "greenPist"),
    "greenStock": map-get($theme-colors, "greenStock"),
    "softGreen": map-get($theme-colors, "softGreen"),
    "orange": map-get($theme-colors, "orange"),
    "lightOrange": map-get($theme-colors, "lightOrange"),
    "turquoise": map-get($theme-colors, "turquoise"),
    "pinkWithoutOp": map-get($theme-colors, "pinkWithoutOp"),
    "lightPink": map-get($theme-colors, "lightPink"),
    "softPink": map-get($theme-colors, "softPink"),
    "greenWithoutOp": map-get($theme-colors, "greenWithoutOp"),
    "redWithoutOp": map-get($theme-colors, "redWithoutOp"),
    "lightBlack": map-get($theme-colors, "lightBlack"),
    "brightRed": map-get($theme-colors, "brightRed"),
    "lightRose": map-get($theme-colors, "lightRose"),
    "moutarde": map-get($theme-colors, "moutarde"),
    "lightYellow": map-get($theme-colors, "lightYellow"),
    "yellowOrange": map-get($theme-colors, "yellowOrange"),
    "wildStrawberry": map-get($theme-colors, "wildStrawberry"),
    "aquaGreen": map-get($theme-colors, "aquaGreen"),
    "iceberg": map-get($theme-colors, "iceberg"),
    "papaya": map-get($theme-colors, "papaya"),
    "darkBlue": map-get($theme-colors, "darkBlue"),
    "blueTurquoise": map-get($theme-colors, "blueTurquoise"),
    "light-grey-1": map-get($theme-colors, "light-grey-1"),
    "pink": map-get($theme-colors, "pink"),
    "green": map-get($theme-colors, "green"),
    "lightBlue": map-get($theme-colors, "lightBlue"),
    "lightBluee": map-get($theme-colors, "lightBluee"),
    "redCalendar": map-get($theme-colors, "redCalendar"),
    "yellow": map-get($theme-colors, "yellow"),
    "purple": map-get($theme-colors, "purple"),
    "blueSky": map-get($theme-colors, "blueSky"),
    "jasminePurple": map-get($theme-colors, "jasminePurple"),
    "yellowGreen": map-get($theme-colors, "yellowGreen"),
    "snowGreen": map-get($theme-colors, "snowGreen"),
    "treebrown": map-get($theme-colors, "treebrown"),
    "oakBrown": map-get($theme-colors, "oakBrown"),
    "brown": map-get($theme-colors, "brown"),
    "light-brown": map-get($theme-colors, "light-brown"),
    "whiteGrey": map-get($theme-colors, "whiteGrey"),
    "red-400": map-get($theme-colors, "red-400"),
    "red-500": map-get($theme-colors, "red-500"),
    "green-200": map-get($theme-colors, "green-200"),
    "green-700": map-get($theme-colors, "green-700"),
    "bronze-500": map-get($theme-colors, "bronze-500"),
    "gray-500": map-get($theme-colors, "gray-500"),
    "blue-200": map-get($theme-colors, "blue-200"),
  ),
  $table-variants
);

@import "~bootstrap/scss/bootstrap.scss";
