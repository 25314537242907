.approvDetailsHeader {
  padding-left: 2.25rem !important;
}
.approvDetailsItem {
  padding-left: 2.25rem !important;
}
.approvDetailsItem:hover {
  background-color: #d9ebf2;
}
.approvDetailsItemContainer:last-of-type hr {
  display: none;
}
