.evenementModal .carousel .carousel-control-prev,
.evenementModal .carousel .carousel-control-next {
  opacity: 1;
}
.evenementModal .carousel .carouselArrow {
  width: 29px;
  height: 29px;
  border-radius: 50%;
  transition: all ease-in-out 0.2s;
}
.evenementModal .carousel .carouselArrow:has(.bi-chevron-right):hover {
  transform: translateX(5px);
}
.evenementModal .carousel .carouselArrow:has(.bi-chevron-left):hover {
  transform: translateX(-5px);
}
.evenementModal .carousel .carouselArrow i {
  -webkit-text-stroke: 1px;
}
.evenementModal .carousel .carouselArrow i.bi-chevron-right {
  padding-left: 2px;
}
.evenementModal .carousel .carouselArrow i.bi-chevron-left {
  padding-right: 2px;
}

.evenementModal .carousel-inner {
  height: 270px;
}
.evenementModal .carousel-item,
.evenementModal .carousel-item img:first-child {
  height: 100% !important;
}
.evenementModal .fileUploaderContainer div > div {
  width: 100% !important;
}
.evenementModal .fileUploaderContainer .upload-btn-container {
  width: 100% !important;
  border-radius: 10px;
}
.evenementModal .fileUploaderContainer .upload-btn-icon {
  height: unset;
  width: 50px;
}
.evenementModal .switchButtonContainer .switchButton {
  background-color: #59a8c4;
}
.evenementModal .switchButtonContainer .switchButton .filterSwitchCircle {
  background-color: white;
}
