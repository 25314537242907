.calendarHeader .slot {
  height: 80px;
  width: calc(100% / 7);
  border-right: 1px solid #d9e1e7cc;
  border-top: 1px solid #d9e1e7cc;
}
.calendarHeader .slot.firstSlot {
  border-left: 0px;
}
.calendarHeader .slot.lastSlot {
  border-right: 0px;
}
