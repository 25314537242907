.listHeaderButtons .downloadButton {
  width: 40px;
  height: 40px;
  background-color: #d0e3e8;
}

.listHeaderButtons .downloadButton:hover {
  outline: 2px #14748e solid;
}
.listHeaderButtons .downloadButton i {
  color: #14748e;
  -webkit-text-stroke: 0.5px;
}
.listHeaderButtons .deleteButton {
  width: 40px;
  height: 40px;
  background-color: #ffcfcf;
}
.listHeaderButtons .deleteButton:hover {
  outline: 2px #ff0d0d solid;
}
.listHeaderButtons .deleteButton i {
  color: #ff0d0d;
  -webkit-text-stroke: 0.3px;
}

.listHeaderButtons .deleteButton:disabled {
  background-color: var(--bs-lightGrey) !important;
  cursor: not-allowed;
  pointer-events: none;
}
.listHeaderButtons .downloadButton:disabled {
  background-color: var(--bs-lightGrey) !important;
  cursor: not-allowed;
  pointer-events: none;
}
.listHeaderButtons .downloadButton:disabled i {
  color: var(--bs-darkGrey);
}

.listHeaderButtons .deleteButton:disabled i {
  color: var(--bs-darkGrey) !important;
}
