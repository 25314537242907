.checkBoxContainer {
  width: fit-content;
}
.checkBoxContainer .form-check-input[type="checkbox"]:focus {
  box-shadow: none;
}
.checkBoxContainer .form-check-input[type="checkbox"]:checked {
  background-color: #14748e;
  border-color: #14748e !important;
}
.checkBoxContainer .form-check-input[type="checkbox"] {
  width: 19px;
  height: 19px;
  border-radius: 6px;
}
