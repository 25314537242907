.left-aligned {
  margin-left: 0%;
}
/* 
#SvgjsG1122:hover {
  transform: translate(-13px, -10px) !important;
}
#SvgjsG1109:hover {
  transform: translate(0px, 13px) !important;
}
#SvgjsG1096 :hover {
  transform: translate(10px, 10px) !important;
}
#SvgjsG1083:hover {
  transform: translate(12px, -5px) !important;
} */
