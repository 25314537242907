.productCardLink {
  all: unset;
}
.productCardLink:hover {
  all: unset;
}
.productCard {
  width: 23%;
  margin: 0px 2% 2% 0px;
  min-width: 340px;
  box-shadow: 0px 2px 6px #0000000d;
  overflow: hidden;
  transition: all 0.01s ease-in-out;
}
.productCard:hover {
  transform: scale(1.02);
  box-shadow: 0px 2px 22px #00000033;
}
.productCard:hover,
.productCard:hover h3 {
  color: #14748e !important;
}
.productCard .productImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.productCard .btnsContainer button {
  height: 40px;
}
.productCard .productCardContent {
  height: calc(100% - 260px) !important;
}
.productCard .productImageContainer {
  height: 228px;
}
