.tabsNavbar {
  padding: 1rem 2rem 1rem 2rem;
}
.tabsNavbar.center {
  padding: 1rem 10%;
  justify-content: space-between;
}
.tabsNavbar:not(.secondary) button::after {
  content: "";
  display: block;
  position: absolute;
  bottom: var(--bottomPosition) !important;
  height: 4px;
  width: 0px;
  background: transparent;
  transition: width 0.5s ease, background-color 0.5s ease;
}
.tabsNavbar button:hover {
  color: #59a8c4 !important;
}
.tabsNavbar button:hover::after {
  width: 100%;
  background: #59a8c4;
}
.tabsNavbar button.active {
  color: #59a8c4 !important;
}
.tabsNavbar button.active::after {
  width: 100%;
  background: #59a8c4;
}
.tabsNavbar.botBorder {
  border-bottom: 1px solid #e1e1e1;
}

.tabsNavbar.secondary * {
  transition: all 0.2s ease-in-out;
}
.tabsNavbar.secondary button.active {
  background-color: var(--bs-darkBlue) !important;
}

.tabsNavbar.secondary button {
  color: var(--bs-darkBlue) !important;
}
.tabsNavbar.secondary button.active {
  color: white !important;
}
