.loginForm {
  width: 550px;
  padding: 20px 0 50px 0;

  /* height: 450px; */
}
.forgotPasswordLink {
  text-decoration: underline !important;
}
.forgotPasswordLink:hover {
  color: #59a8c4 !important;
}
.loginPasswordInput {
  margin-bottom: 0.3rem !important;
}
.loginForm .errorAlert {
  height: 40px;
}
.loginForm .errorMessageContainer {
  height: 30px;
}
