.textInputContainer .textInput {
  height: 40px;
}
.textInputContainer .textInput:focus-visible {
  outline: none;
  border: 2px #14748e solid !important;
}

.textInputContainer .textInput:hover {
  border: 2px #14748e solid !important;
}

.textInputContainer .textInput::placeholder {
  color: #656565;
  font-size: var(--placeholderStyle) !important;
  font-weight: 400;
}
.textInputContainer .textInput:disabled {
  border-color: #dfe5e9 !important;
  cursor: text;
}
.textInputContainer .searchBtn {
  position: absolute;
  border: none;
  background-color: #14748e;
  top: 0;
  right: 0;
  border-radius: 6px;
  padding: 0 15px;
}
.textInputContainer .euroIcon {
  position: absolute;
  background-color: white;
  top: 2px;
  right: 2px;
  border-radius: 6px;
  padding: 6px 15px;
}

/************************ search input ************************/
.textInputContainer .textInput.searchInput:focus-visible {
  outline: none;
  border: 1px #14748e solid !important;
}
.textInputContainer .textInput.searchInput:hover {
  border: 1px #14748e solid !important;
}
.textInputContainer .textInput.searchinput::placeholder {
  font-size: 15px;
  color: #656565;
}

/************************ currency input ************************/
.currencyDropdownContainer {
  position: absolute;
  background-color: white;
  top: 2px;
  right: 7px;
  border-radius: 6px;
}
.textInputContainer .customSelect {
  height: 26px;
  width: 45px;
}
.textInputContainer .customSelect .p-dropdown-trigger {
  width: 10px;
  margin-right: 8px;
  margin-top: 2px;
}
.textInputContainer .customSelect .p-dropdown-label {
  font-size: 16px;
  margin-left: -5px;
  color: #809fb8 !important;
  overflow: hidden;
  text-overflow: unset;
}
.textInputContainer .customSelect .p-dropdown-trigger .pi {
  font-size: 13px;
}
.textInputContainer .disabled {
  background-color: #fafafa;
}
.currencyDropdown .p-dropdown-item {
  padding: 0.75rem 0.9rem !important;
}

.p-dropdown-trigger {
  color: #809fb8 !important;
}
