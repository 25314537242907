.f10 {
  font-size: 10px;
}
.f11 {
  font-size: 11px;
}
.f12 {
  font-size: 12px;
}
.f13 {
  font-size: 13px;
}
.f14 {
  font-size: 14px;
}
.f15 {
  font-size: 15px;
}
.f16 {
  font-size: 16px;
}
.f17 {
  font-size: 17px;
}
.f18 {
  font-size: 18px;
}
.f19 {
  font-size: 19px;
}
.f20 {
  font-size: 20px;
}
.f21 {
  font-size: 21px;
}
.f22 {
  font-size: 22px;
}
.f23 {
  font-size: 23px;
}
.f24 {
  font-size: 24px;
}
.f25 {
  font-size: 25px;
}
.f26 {
  font-size: 26px;
}
.f27 {
  font-size: 27px;
}
.f30 {
  font-size: 30px;
}
.f31 {
  font-size: 31px;
}
.f33 {
  font-size: 33px;
}
.f34 {
  font-size: 34px;
}
.f35 {
  font-size: 35px;
}
.f40 {
  font-size: 40px;
}
.f44 {
  font-size: 44px;
}
.f45 {
  font-size: 45px;
}
.f48 {
  font-size: 48px;
}
.f50 {
  font-size: 50px;
}
.f60 {
  font-size: 65px;
}
.f65 {
  font-size: 65px;
}
.f70 {
  font-size: 70px;
}
.f75 {
  font-size: 75px;
}
.f100 {
  font-size: 100px;
}

@media only screen and (max-width: 992px) {
  .fm10 {
    font-size: 10px;
  }
  .fm12 {
    font-size: 12px;
  }
  .fm13 {
    font-size: 13px;
  }
  .fm14 {
    font-size: 14px;
  }
  .fm15 {
    font-size: 15px;
  }
  .fm16 {
    font-size: 16px;
  }
  .fm18 {
    font-size: 18px;
  }
  .fm20 {
    font-size: 20px;
  }
  .fm22 {
    font-size: 22px;
  }
  .fm25 {
    font-size: 25px;
  }
  .fm30 {
    font-size: 30px;
  }
  .fm35 {
    font-size: 35px;
  }
  .fm40 {
    font-size: 40px;
  }
  .fm45 {
    font-size: 45px;
  }
  .fm48 {
    font-size: 48px;
  }
  .fm50 {
    font-size: 50px;
  }
  .fm60 {
    font-size: 65px;
  }
  .fm65 {
    font-size: 65px;
  }
  .fm70 {
    font-size: 70px;
  }
  .fm75 {
    font-size: 75px;
  }
  .fm100 {
    font-size: 100px;
  }
}
