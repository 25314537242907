.shadowLayerContent {
  opacity: 0.4;
}
.shadowSpinnerContainer {
  position: absolute;
  top: 50%;
  left: 40%;
}
.shadowLayerContainer {
  pointer-events: none;
}
