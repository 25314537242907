.cartSelectorItem {
  color: #14748e;
  position: relative;
  border-radius: 10px 10px 0px 0px;
  transform: translateY(2px);
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  transition: all 0.2s ease-in-out;
  background-color: white;
}
.cartUnSelected {
  color: rgb(179, 179, 179);
  background-color: rgb(20, 116, 142, 0.8);
}
.cartUnSelected::after {
  transform: translateY(-2px);
  content: "";
  height: 2px;
  background-color: #14748e;
  position: absolute;
  top: 100%;
  left: -2px;
  width: 103%;
  transition: all 0.2s ease-in-out;
}
.cartSelected::after {
  transform: translateY(-2px);
  content: "";
  height: 2px;
  background-color: white;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  transition: all 0.2s ease-in-out;
}
.cartSelectorContainer {
  border-bottom: 2px solid #14748e;
  transition: all 0.2s ease-in-out;
  position: sticky;
  top: 86px;
  z-index: 1;
  background-color: white;
  flex-wrap: wrap;
}
.cartSelectorContainer {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.cartSelectorContainer::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
.deleteCart {
  position: absolute;
  top: -9px;
  right: -10px;
  background-color: white;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  border: 1px solid #14748e;
}
.deleteCart:hover {
  background-color: rgb(20, 116, 142);
  color: wheat !important;
}
.deleteCart:hover i {
  color: white !important;
}

.cartSelected {
  border-color: #14748e;
}
.addCartButton {
  border: 2px solid #14748e;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: #14748e;
  transition: all 0.2s ease-in-out;
}
.addCartButton:hover {
  background-color: #14748e;
  color: white;
}
.addCartButton:active {
  transform: scale(1.1);
  opacity: 0.5;
}
