.longSwitch {
  width: 200px;
  height: 40px;
  background-color: #d9e1e7;
}
.longSwitch .highlighter {
  position: absolute;
  height: 34px;
  width: 97px;
  left: 3px;
  transition: all ease-in-out 0.3s;
}
.longSwitch .btnText.active {
  color: white !important;
  transition: all ease-in-out 0.4s;
}
.longSwitch .highlighter.active {
  transform: translateX(97px);
}
