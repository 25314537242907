.panier .panierFooter {
  position: fixed;
  bottom: 0px;
  min-width: 285px;
  background-color: white;
}
.panierContent {
  overflow-y: auto;
  max-height: calc(100vh - 375px);
}
