.paymentSteps .iconContainer {
  width: 60px;
  height: 60px;
}
.paymentSteps .iconContainer.active {
  background-color: #666666 !important;
}
.paymentSteps .panierLine {
  width: 110px;
  height: 3px;
}
.paymentSteps .stepContainer {
  margin-bottom: -1rem;
}
