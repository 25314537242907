.orderCardContainer {
  margin: 0 !important;
}
.orderCardContainer:hover {
  background-color: var(--bs-dimGrey);
}
.orderCardContainer i.bi {
  -webkit-text-stroke: 0.5px;
}
.p-tooltip.primeCustomToolTip > div {
  height: fit-content !important;
}
.orderCardContainer.show .verticalPoints,
.orderCardContainer.show .verticalPoints.active {
  color: white !important;
}
.orderCardContainer.show .verticalPoints.active:hover {
  color: #14748e !important;
}
.adminCommandesViewHeader .bi-plus-lg {
  -webkit-text-stroke-width: 0.5px;
}
.packingListForm .p-dropdown .p-dropdown-trigger {
  width: 2rem;
}
