.calendarContainer .p-calendar,
.calendarContainer .p-datepicker,
.calendarContainer .p-inputwrapper,
.calendarContainer {
  border: 0 !important;
  border-style: none !important;
}

.calendarContainer .p-datepicker {
  width: 500px !important;
}

.calendarContainer .p-datepicker table td > span.p-highlight {
  background-color: #59a8c4 !important;
  color: white !important;
}

.calendarContainer .p-datepicker table th > span {
  color: #666666 !important;
  font-weight: 600;
  font-size: 15px;
}

.calendarContainer .p-datepicker table th {
  text-align: center;
}
.calendarContainer .p-datepicker table td > span {
  color: #666666 !important;
  font-weight: 400;
  font-size: 16px;
}

.calendarContainer .p-datepicker table td.p-datepicker-today > span {
  background: #59a8c4 !important;
  color: white !important;
}

.calendarContainer .p-datepicker-calendar .p-disabled {
  color: #d9e1e7 !important;
}

.pi {
  color: #809fb8;
}

.calendarContainer .p-datepicker .p-datepicker-header {
  border-bottom: none !important;
  justify-content: center !important;
}

.p-datepicker-header .p-datepicker-title {
  margin: 0 5% !important;
}

.calendarContainer .p-datepicker .p-datepicker-header .p-link {
  color: #666666 !important;
  font-size: 18px !important;
  font-weight: 700 !important;
}

.calendarContainer .p-datepicker table td > span.p-highlight {
  position: relative;
  overflow: unset !important;
  background: transparent !important;
  color: #666666 !important;
  border: none;
}

.calendarContainer .p-datepicker-today span {
  color: white !important;
}
.calendarContainer .p-datepicker-today span:hover {
  color: var(--bs-brightGrey) !important;
}

.calendarContainer .customEventDay:hover {
  background-color: #e9ecef !important;
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.calendarContainer .eventDayIndicator {
  bottom: 3px;
}
