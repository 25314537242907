.attachmentItem.custom.checked {
  background-color: #f8f8f8;
}

.documentLink {
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
}
.attachmentItem.custom .documentLink:hover {
  text-decoration: underline !important;
}
.attachmentItem.custom .bi {
  -webkit-text-stroke: 0.5px;
}
.attachmentItem.custom .buttonsContainer button {
  width: 35px;
  height: 35px;
  position: relative;
}

.attachmentItem.custom .buttonsContainer button:hover {
  background-color: #d0e3e8 !important;
}
.attachmentItem.custom .buttonsContainer button.deleteBtn:hover {
  background-color: #ffcfcf !important;
}
