.verticalPointsContainer {
  width: fit-content;
}
.verticalPointsContainer .verticalPoints {
  -webkit-text-stroke: 0.4px;
}
.verticalPointsContainer .iconContainer {
  height: 33px;
  width: 33px;
}
.verticalPointsContainer .iconContainer:hover {
  background-color: #efefef !important;
}
.verticalPointsContainer .iconContainer:hover .verticalPoints {
  color: #14748e !important;
}
.verticalPointsContainer .iconContainer .verticalPoints.active {
  color: #14748e !important;
}

.verticalPointsContainer .detailsContainer {
  box-shadow: 0px 3px 6px #00000029;
  top: 33px;
  overflow: hidden;
}
.optionBtn {
  text-align: start !important;
}
.verticalPointsContainer .detailsContainer .optionBtn:hover {
  background-color: #efefef !important;
}
.verticalPointsContainer .detailsContainer.showLeft {
  right: 3px;
}
