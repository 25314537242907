.generalInfo .memberImageContainer {
  height: 146px;
  width: 146px;
}
.generalInfo .memberImageContainer .iconContainer {
  width: 30px;
  height: 30px;
  position: absolute;
  left: 115px;
  bottom: 10px;
}
.generalInfo .birthdayInput {
  height: 40px;
}
