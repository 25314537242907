/***************************** product carousel ******************************/
.productDetails .productCarousel {
  min-width: 400px;
}
.productDetails .productCarousel .carousel {
  height: 500px;
}
.productDetails .carousel-inner,
.productDetails .carousel-item,
.productDetails .carousel-item > img {
  height: 100%;
  object-fit: contain;
}

.productDetails .productCarousel img {
  transition: all 0.2s ease-in-out;
}
.productDetails .productCarousel img.active {
  background-color: var(--bs-fadedBlue);
  padding: 0 10px;
}
.productDetails .carousel-control-next,
.productDetails .carousel-control-prev {
  opacity: 1;
  width: 50px;
  height: 50px;
  margin: auto 0;
  border-radius: 50%;
  color: var(--bs-smoothBlue);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
}
.productDetails .carousel-control-next i:before,
.productDetails .carousel-control-prev i:before {
  font-weight: 700 !important;
}
.productDetails .carousel-control-next:hover,
.productDetails .carousel-control-prev:hover {
  color: var(--bs-darkBlue);
}

/***************************** product table ******************************/

.productDetails .productTableHeader {
  height: 100px;
}
.productDetails .productTableHeader button {
  align-items: center;
}

/***************************** product Attachment ******************************/
.productDetails .productAttachmentIcon {
  width: 70px;
  height: 70px;
  transition: all 0.1s ease-in-out;
}
.productDetails .productAttachmentLink {
  text-decoration: none;
  color: #666666;
}
.productDetails .productAttachmentLink:hover {
  color: #14748e;
}
.productDetails .productAttachmentLink:hover .productAttachmentIcon {
  background-color: #14748e !important;
}

/***************************** product simulator ******************************/
.productDetails .productColorsSimulator .colorPicker {
  width: 40px;
  height: 40px;
  box-shadow: 0px 3px 6px #00000029;
}
.productDetails .productColorsSimulator .colorPicker.active {
  border: 3px white solid !important;
}
.productDetails .productColorsSimulator {
  height: 480px;
  padding: 5%;
}
.productDetails .simulatorImage {
  margin-top: -7%;
}
