.sideBar {
  background-color: white;
  position: fixed;
  right: 0px;
  box-shadow: 0px 2px 15px #0000000d;
  height: calc(100vh - 86px);
  padding-top: 30px;
  margin-top: -30px;
  min-width: 330px;
  overflow-y: scroll;
}
