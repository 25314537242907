.custom-btn {
  padding-top: 8px;
  padding-bottom: 8px;
  min-height: 40px;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-btn i.bi-plus-lg {
  -webkit-text-stroke: 0.6px;
}

.outline-btn:hover {
  color: white !important;
  background-color: #14748e !important;
}
.custom-btn .spinner-border {
  width: 1.5rem;
  height: 1.5rem;
}

.small-spinner {
  width: 20px !important;
  height: 20px !important;
}

.tab-disabled button:disabled::after {
  display: none;
}

.tab-disabled button:disabled,
.tab-disabled button:hover:disabled {
  border: 0 !important;
  color: var(--bs-grey) !important;
}
