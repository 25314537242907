.p-multiselect-panel {
  z-index: 9999 !important;
}
.p-multiselect-header {
  /* display: none !important; */
  position: relative;
  z-index: 999999;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #495057 !important;
  background-color: #eef2ff !important;
}
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #2d87a0 !important;
  background: #2d87a0 !important;
}
.p-multiselect {
  box-shadow: none !important;
}
.p-multiselect:hover,
.p-multiselect-panel .p-inputtext:enabled:hover,
.p-multiselect.p-focus {
  border-color: #2d87a0 !important;
}

.p-multiselect.direction-top .p-multiselect-panel {
  top: unset !important;
  bottom: 40px !important;
  left: unset;
  right: 0;
}

.p-multiselect.h-40px .p-multiselect-label {
  display: flex !important;
  align-items: center !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 100% !important;
}
