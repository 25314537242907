.soldProductsContainer {
  box-shadow: 0px 2px 15px #0000001f;
  min-width: 330px;
}

.statColor {
  width: 14px;
  height: 14px;
}

.apexcharts-canvas {
  width: 380px !important;
}
