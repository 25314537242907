.productItem {
  width: 285px;
}
.counterIcon::before {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-top: 2px;
  border-radius: 50%;
}
.counterIcon:hover::before {
  color: #14748e;
  background-color: #efefef;
}
