.br1 {
  border-radius: 1px;
}
.br2 {
  border-radius: 2px;
}
.br3 {
  border-radius: 3px;
}
.br4 {
  border-radius: 4px;
}
.br5 {
  border-radius: 5px;
}
.br6 {
  border-radius: 6px;
}
.br7 {
  border-radius: 7px;
}
.br8 {
  border-radius: 8px;
}
.br9 {
  border-radius: 9px;
}
.br10 {
  border-radius: 10px;
}
.br20 {
  border-radius: 20px;
}
.br5500 {
  border-radius: 5px 5px 0px 0px;
}
.br6600 {
  border-radius: 6px 6px 0px 0px;
}
.br0066 {
  border-radius: 0px 0px 6px 6px;
}
.br0055 {
  border-radius: 0px 0px 5px 5px;
}
.br50 {
  border-radius: 50% !important;
}
