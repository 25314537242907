.calendarContent .calendarSlot {
  height: 200px;
  width: calc(100% / 7);
  border-right: 1px solid #d9e1e7cc;
  border-top: 1px solid #d9e1e7cc;
  overflow-y: auto;
}
.calendarContent .calendarSlot.lastInLine {
  border-right: 0px;
}

.calendarContent .slotDate {
  width: 30px;
  min-height: 30px;
  padding-top: 4px;
}
.calendarContent .slotDate.isCurrentDate {
  background-color: #14748e;
  color: white !important;
}
.calendarEventContainer .dropdownContainer {
  position: fixed;
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  z-index: 4;
}
