.deliveryCalendar .p-datepicker {
  background-color: #f5f5f5;
  border: none;
}
.deliveryCalendar .p-datepicker .p-datepicker-header {
  background-color: #f5f5f5;
}
.deliveryCalendar .p-datepicker thead span {
  color: #abb8b2 !important;
}
.deliveryDialog .calendarContainer .p-datepicker table td > span.p-highlight {
  background-color: rgb(217, 217, 255) !important;
}
