.customSelect {
  border: 2px solid #d9e1e7 !important;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative !important;
}
.customAutocomplete {
  border: 2px solid #d9e1e7 !important;
  outline: none !important;
  transition: all 0.2s ease-in-out;
}
.customAutocomplete:hover {
  border: 2px #14748e solid !important;
}
.customAutocomplete .p-inputtext {
  outline: none;
  border: none;
}
.p-dropdown-panel.p-component {
  z-index: 1100 !important;
}
.p-autocomplete-panel {
  z-index: 11000 !important;
}

.customSelect:hover {
  border: 2px #14748e solid !important;
}
.customSelect.p-focus {
  border: 2px #14748e solid !important;
}
.customSelect .p-placeholder {
  font-size: 16px !important;
  color: #666666 !important;
  font-family: "Open sans";
  font-weight: 500;
}
.customSelect .p-dropdown-label {
  color: #06152b !important;
  font-size: var(--valueCustomStyle) !important;
  font-weight: normal;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  font-size: var(--valueCustomStyle) !important;
  color: var(--valueCustomColor) !important;
}
