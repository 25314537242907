.modifyOrder > .buttonsContainer button {
  width: 190px;
}
/* .modifyOrder .modalContent {
  padding: 2rem;
} */
.modifyOrder .quantityInput input {
  text-align: center;
  padding: 0px !important;
}
.modifyOrder .discountInput {
  text-align: center;
  padding: 0px !important;
}
.modifyOrder .discountInput .euroIcon {
  padding: 0px;
  top: 7px;
  right: 10px;
}
.modifyOrder .quantityInput input::-webkit-outer-spin-button,
.modifyOrder .quantityInput input::-webkit-inner-spin-button,
.modifyOrder .discountInput input::-webkit-outer-spin-button,
.modifyOrder .discountInput input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.modifyOrder .quantityInput input[type="number"] {
  -moz-appearance: textfield;
}

.modifyOrder .paymentDateInput {
  height: 40px;
}
.deliverySummary .p-dialog-content {
  border-radius: 6px;
  padding: 3rem !important;
  width: 80vw;
}
.deliverySummary .buttonsContainer button {
  width: 150px;
}
.deliverySummary .summarymessage {
  height: 91%;
}
.confirmationDialog .p-dialog-content {
  padding-top: 1rem !important;
  width: 35vw;
  border-radius: 0px;
}
