.panierDetails .trashIconContainer {
  height: 30px;
  width: 30px;
}
.panierDetails .trashIconContainer i {
  -webkit-text-stroke: 0.3px;
}
.panierDetails .trashIconContainer:hover {
  background-color: #ff000029;
}
.panierItemImage {
  object-fit: contain;
}
