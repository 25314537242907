.rightModalContent {
  margin-top: 86px;
  /* width: 50vw !important; */
  height: calc(100vh - 86px) !important;
  padding-top: 0;
  border-radius: 0 !important;
  overflow: auto;
}
.rightModalHeader {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 1rem 0px 0px;
}
.rightModalBody {
  padding: 0px;
  overflow: visible;
}
.modal-open {
  padding-right: 0 !important;
}

.fade .modal .show {
  padding: 0;
}

.rightModalContent .modal-header {
  border-bottom: 0;
}

.offcanvas-backdrop {
  background-color: #65646459 !important;
}

.offcanvas.offcanvas-end {
  border: 0;
}
