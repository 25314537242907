.wfitCon {
  width: fit-content !important;
}
.w1 {
  width: 1%;
}
.w2 {
  width: 2%;
}
.w3 {
  width: 3%;
}
.w4 {
  width: 4%;
}
.w5 {
  width: 5%;
}
.w6 {
  width: 6%;
}
.w7 {
  width: 7%;
}
.w8 {
  width: 8%;
}
.w9 {
  width: 9%;
}
.w10 {
  width: 10%;
}
.w11 {
  width: 11%;
}
.w12 {
  width: 12%;
}
.w13 {
  width: 13%;
}
.w14 {
  width: 14%;
}
.w15 {
  width: 15%;
}
.w16 {
  width: 16%;
}
.w17 {
  width: 17%;
}
.w18 {
  width: 18%;
}
.w19 {
  width: 19%;
}
.w20 {
  width: 20%;
}
.w21 {
  width: 21%;
}
.w22 {
  width: 22%;
}
.w23 {
  width: 23%;
}
.w24 {
  width: 24%;
}
.w25 {
  width: 25%;
}
.w26 {
  width: 26%;
}
.w27 {
  width: 27%;
}
.w28 {
  width: 28%;
}
.w29 {
  width: 29%;
}
.w30 {
  width: 30%;
}
.w31 {
  width: 31%;
}
.w32 {
  width: 32%;
}
.w33 {
  width: 33%;
}
.w34 {
  width: 34%;
}
.w35 {
  width: 35%;
}
.w36 {
  width: 36%;
}
.w37 {
  width: 37%;
}
.w38 {
  width: 38%;
}
.w39 {
  width: 39%;
}
.w40 {
  width: 40%;
}
.w41 {
  width: 41%;
}
.w42 {
  width: 42%;
}
.w43 {
  width: 43%;
}
.w44 {
  width: 44%;
}
.w45 {
  width: 45%;
}
.w46 {
  width: 46%;
}
.w47 {
  width: 47%;
}
.w48 {
  width: 48%;
}
.w49 {
  width: 49%;
}
.w50 {
  width: 50%;
}
.w51 {
  width: 51%;
}
.w52 {
  width: 52%;
}
.w53 {
  width: 53%;
}
.w54 {
  width: 54%;
}
.w55 {
  width: 55%;
}
.w56 {
  width: 56%;
}
.w57 {
  width: 57%;
}
.w58 {
  width: 58%;
}
.w59 {
  width: 59%;
}
.w60 {
  width: 60%;
}
.w61 {
  width: 61%;
}
.w62 {
  width: 62%;
}
.w63 {
  width: 63%;
}
.w64 {
  width: 64%;
}
.w65 {
  width: 65%;
}
.w66 {
  width: 66%;
}
.w67 {
  width: 67%;
}
.w68 {
  width: 68%;
}
.w69 {
  width: 69%;
}
.w70 {
  width: 70%;
}
.w71 {
  width: 71%;
}
.w72 {
  width: 72%;
}
.w73 {
  width: 73%;
}
.w74 {
  width: 74%;
}
.w75 {
  width: 75%;
}
.w76 {
  width: 76%;
}
.w77 {
  width: 77%;
}
.w78 {
  width: 78%;
}
.w79 {
  width: 79%;
}
.w80 {
  width: 80%;
}
.w81 {
  width: 81%;
}
.w82 {
  width: 82%;
}
.w83 {
  width: 83%;
}
.w84 {
  width: 84%;
}
.w85 {
  width: 85%;
}
.w86 {
  width: 86%;
}
.w87 {
  width: 87%;
}
.w88 {
  width: 88%;
}
.w89 {
  width: 89%;
}
.w90 {
  width: 90%;
}
.w91 {
  width: 91%;
}
.w92 {
  width: 92%;
}
.w93 {
  width: 93%;
}
.w94 {
  width: 94%;
}
.w95 {
  width: 95%;
}
.w96 {
  width: 96%;
}
.w97 {
  width: 97%;
}
.w98 {
  width: 98%;
}
.w99 {
  width: 99%;
}
.w100 {
  width: 100%;
}

@media only screen and (max-width: 960px) {
  .wm10 {
    width: 10%;
  }
  .wm20 {
    width: 20%;
  }
  .wm30 {
    width: 30%;
  }
  .wm40 {
    width: 40%;
  }
  .wm45 {
    width: 45%;
  }
  .wm50 {
    width: 50%;
  }
  .wm60 {
    width: 60%;
  }
  .wm70 {
    width: 70%;
  }
  .wm80 {
    width: 80%;
  }
  .wm90 {
    width: 90%;
  }
  .wm100 {
    width: 100%;
  }
}

.w300 {
  width: 300px !important;
}
.minh300 {
  min-height: 300px;
}
