.drawerContainer {
  width: 80px;
  overflow: scroll;
  top: 86px;
  height: calc(100vh - 86px);
  box-shadow: 0px 3px 25px #00000014;
  position: fixed;
  z-index: 4;
}
.drawerContainer:hover {
  width: 290px;
}
.drawLink,
.drawLink-BusinessBroker,
.drawLink-reseller,
.drawLink-admin {
  width: 48px;
  height: 44px;
  margin-left: 1rem;
  padding-left: 8px;
}
.drawLink div,
.drawLink-reseller div,
.drawLink-admin div,
.drawLink-BusinessBroker div {
  gap: 35px;
}
.drawerContainer:hover .drawLink,
.drawerContainer:hover .drawLink-BusinessBroker,
.drawerContainer:hover .drawLink-reseller,
.drawerContainer:hover .drawLink-admin {
  width: 260px;
}
.linkName {
  white-space: nowrap;
}
.logoNavigations {
  height: fit-content;
  flex: 1;
}
.drawLink,
.drawLink-BusinessBroker,
.drawLink-reseller,
.drawLink-admin {
  color: #2c2c2c;
  gap: 30px;
  transform-origin: left;
}
.drawLink:hover {
  color: white;
  background-color: #14748e;
}
.drawLink-reseller:hover {
  color: white;
  background-color: #14748e;
}
.drawLink-admin:hover {
  color: white;
  background-color: var(--bs-darkBlue);
}
.drawLink-BusinessBroker:hover {
  color: white;
  /* background-color: var(--bs-blueTurquoise); */
  background-color: var(--bs-darkBlue);
}
.drawLink svg,
.drawLink-BusinessBroker svg,
.drawLink-reseller svg,
.drawerLink-admin svg {
  fill: #666666;
}
.drawLink:hover svg {
  fill: white !important;
}
.inpage {
  background-color: #14748e;
}
.logout {
  color: white;
  gap: 30px;
  cursor: pointer;
  position: relative;
}
.logout:hover svg path {
  fill: white;
}
