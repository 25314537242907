.b-1-lightGrey {
  border: 1px solid #efefef !important;
}
.b-1-darkGrey {
  border: 1px solid #abb8b2 !important;
}
.b-2-darkGrey {
  border: 2px solid #abb8b2 !important;
}
.b-2-softGrey {
  border: 2px solid #d9e1e7 !important;
}
.b-1-bottom-lightGrey {
  border-bottom: 1px solid #abb8b2 !important;
}
.b-1-bottom-white {
  border-bottom: 1px solid white !important;
}
.b-1-bottom-softGrey {
  border-bottom: 1px solid #f1f4f9 !important;
}
.b-2-white {
  border: 2px solid #fff !important;
}
.b-3-white {
  border: 3px solid #fff !important;
}

.b-1-right-dimGrey {
  border-right: 1px solid #f8f8f8 !important;
}
.b-1-right-softGrey {
  border-right: 1px solid #f1f4f9 !important;
}
.b-1-left-dimGrey {
  border-left: 1px solid #f8f8f8 !important;
}

.b-1-bottom-dimGrey {
  border-bottom: 1px solid #f8f8f8 !important;
}
